/**
 * WARNING
 * WARNING This file is intended as a minimal JS loader.  As such, we need to be very careful about
 * WARNING what we load in this file.  We don't want it to become too big.  We don't want to even
 * WARNING include common libraries like lodash or jquery.
 * WARNING
 */
// Don't use directly but loading it adds yepnope to the 'window'
import yepnope from 'util/yepnope/yepnope';
import SiteSpectCookieUtils from 'util/site_spect_cookie_utils';
import Sentry from '../sentry-load';


// //
// Compute the download location
//
const basePath = scriptPath();

// Start the loader only once in any page
//
if (typeof window.ASD_CLIENT_LOADED === 'undefined') {
    window.ASD_CLIENT_LOADED = true;

    const hostname = location.hostname;
    const hostMatch = hostname.match(/(dev|perf|qagold|qafix)\.(.*$)/);

    if (hostMatch) {
        //
        // Now update the service base url
        //
        if (typeof ASD_SETTINGS.serviceBaseUrl === 'undefined') {
            //
            // Just set explicitly to the right environment
            //
            ASD_SETTINGS.serviceBaseUrl = '//partners.' + hostMatch[1] + '.allstardirectories.com';
        }
    }
    if (typeof ASD_SETTINGS.serviceBaseUrl === 'undefined') {
        ASD_SETTINGS.serviceBaseUrl = '//partners.allstardirectories.com';
    }

    //Load sentry
    const environments = hostMatch ? hostMatch[1] : 'prod';
    Sentry.sentryLoad(environments);

    // We need to esure this isn't cached so we get a 200 which includes the custom cookie headers
    const siteSpectInitialLoadUri = `${ASD_SETTINGS.serviceBaseUrl
        }/site-spect-init.txt?cacheBust=${Date.now()}`;

    // We do an initial ajax request to ensure that we get SiteSpect test assignments (if
    // available) before we load the client
    const xhr = new XMLHttpRequest();
    xhr.addEventListener('loadend', handleInitialResponse);
    xhr.open('GET', siteSpectInitialLoadUri, true);
    xhr.setRequestHeader(SiteSpectCookieUtils.notABotHeader, 'true');
    // fix CORS console error
    // xhr.setRequestHeader(SiteSpectCookieUtils.accessControlOrigin, '*'); // to avoid duplicate requests in case Cross-Origin Resource Sharing (CORS)
    xhr.setRequestHeader(SiteSpectCookieUtils.requestHeader, document.cookie);
    xhr.send(null);
}

// Check if IE 11 or below
function isIE() {
    const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
    const msie = ua.indexOf('MSIE '); // IE 10 or older
    const trident = ua.indexOf('Trident/'); //IE 11

    return msie > 0 || trident > 0;
}

//
// Simple utility method to obtain parameters from the system
//
function getQueryParam(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
        results = regex.exec(location.search);
    return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

//
// Method to obtain the value for a specific cookie
//
function getCookie(cname) {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        // HACK the replace() is to work around IE8s lack of support for trim()
        const c = ca[i].replace(/^\s+|\s+$/g, '');
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return null;
}

//
// Helper method to obtain a test parameter/cookie value
//
function setTestValue(name, value) {
    //
    // Special logic here:  When obtaining a test value, if we find a non-
    // empty value, add the value as part of the setting block (for use in
    // analytics).  Note that ASD_SETTINGS must be present at this point.
    //
    if (value !== '' && typeof value !== 'undefined' && null !== value) {
        if (!ASD_SETTINGS.testSegments) {
            ASD_SETTINGS.testSegments = {};
        }

        ASD_SETTINGS.testSegments[name] = value;
    }
}

function getTestValue(testName, setValue = true) {
    const testNameLowerCase = testName.toLowerCase();
    let rVal = '';
    const testParam = getQueryParam(testNameLowerCase);
    const testCookie = getCookie(testNameLowerCase);
    if (null !== testParam && '' !== testParam) {
        rVal = testParam;
    } else if (null !== testCookie && '' !== testCookie) {
        rVal = testCookie;
    }

    if (testNameLowerCase && rVal && setValue) {
        setTestValue(testNameLowerCase, rVal);
    }
    return rVal;
}

//
// Method to obtain the URI for the loading path of the current script
//
function scriptPath() {
    const scripts = document.getElementsByTagName('script');
    let path = '';
    if (scripts && scripts.length > 0) {
        for (const i in scripts) {
            if (scripts[i].src && scripts[i].src.match(/\/asd-loader\.js($|\?.*$)/)) {
                path = scripts[i].src.replace(/(.*)\/asd-loader\.js($|\?.*$)/, '$1');
                break;
            }
        }
    }
    return path;
}

//
// Compare two dotted version strings (like '10.2.3').
// @returns {Integer} 0: v1 == v2, -1: v1 < v2, 1: v1 > v2
//
function versionCompare(v1, v2) {
    const v1parts = ('' + v1).split('.'),
        v2parts = ('' + v2).split('.'),
        minLength = Math.min(v1parts.length, v2parts.length);

    //
    // Compare tuple pair-by-pair.
    //
    for (let i = 0; i < minLength; i++) {
        //
        // Convert to integer if possible, because "8" > "10".
        //
        let p1 = parseInt(v1parts[i], 10);
        let p2 = parseInt(v2parts[i], 10);
        if (isNaN(p1)) {
            p1 = v1parts[i];
        }
        if (isNaN(p2)) {
            p2 = v2parts[i];
        }
        if (p1 === p2) {
            continue;
        } else if (p1 > p2) {
            return 1;
        } else if (p1 < p2) {
            return -1;
        }
        // one operand is NaN
        return NaN;
    }
    // The longer tuple is always considered 'greater'
    if (v1parts.length === v2parts.length) {
        return 0;
    }
    return v1parts.length < v2parts.length ? -1 : 1;
}

function handleInitialResponse(event) {
    // Parse SiteSpect custom cookie header in the response and set them as cookies on this domain
    const siteSpectHeader = event.target.getResponseHeader(SiteSpectCookieUtils.responseHeader);
    const siteSpectCookies = SiteSpectCookieUtils.parseCookieHeader(siteSpectHeader);
    siteSpectCookies.forEach(cookie => (document.cookie = cookie));

    // Parse our SiteSpect A/B test headers
    const testHeaders = event.target
        .getAllResponseHeaders()
        .toLowerCase()
        .split('\r\n')
        .filter(header => header.indexOf(SiteSpectCookieUtils.testHeaderPrefix) === 0)
        .map(header => {
            const headerParts = header.split(': ');
            const name = headerParts[0];
            const value = headerParts[1];

            return {
                name,
                value,
            };
        });
    const testsCookies = testHeaders.map(header => {
        const cookieName = header.name.replace(SiteSpectCookieUtils.testHeaderPrefix, '');

        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 1); // 24 hours later

        return `${cookieName}=${header.value}; expires=${expirationDate.toUTCString()}; path=/`;
    });
    testsCookies.forEach(cookie => (document.cookie = cookie));

    //
    // Ensure at least an empty ASD_SETTINGS is present in the namespace
    //
    if (typeof ASD_SETTINGS === 'undefined') {
        ASD_SETTINGS = {};
    }

    const hostMatch = location.hostname.match(/(dev|perf|qagold|qafix)\.(.*$)/);
    const localDevEnvironment = process.env.NODE_ENV === 'development';

    //
    // TODO: add a way to suppress consoles on production?
    //
    // if (!localDevEnvironment && !hostMatch) {
    //     window.console = {
    //         info: () => {},
    //         log: () => {},
    //         warn: () => {},
    //         error: () => {}
    //     };
    //     console = window.console;
    // }

    //
    // When using the validation environments (perf and qagold), reset the
    // basepath and set the url base for the client to the perf/qagold
    // environments.
    //
    if (hostMatch) {
        //
        // Now update the service base url
        //
        if (typeof ASD_SETTINGS.serviceBaseUrl === 'undefined') {
            //
            // Just set explicitly to the right environment
            //
            ASD_SETTINGS.serviceBaseUrl = '//partners.' + hostMatch[1] + '.allstardirectories.com';
        }
    }

    if (localDevEnvironment) {
        ASD_SETTINGS.usingLocalDev = true;
    }

    //
    // Test alternate client versions
    //
    const testVersion = getTestValue('test_version');
    let clientDownloadPath =
        testVersion === '' ? basePath : basePath.replace(/\/client$/, '/' + testVersion);

    const domainMatch = document.domain.match(/\.(\w+)\.\w+$/);
    if (domainMatch) {
        //
        // Enable conversion nav widget if appropriate
        //
        const domain = domainMatch[1];
        if (domain === 'allnursingschools' || domain === 'allpsychologyschools') {
            ASD_SETTINGS.useConversionNavWidget = true;
        }
    }

    //
    // This test segment is used for test automation and is a generic test
    // segment that may be used for standard AB Testing.  Since this is used
    // by automation, this test check should not be removed.
    //
    const useGenericTest = getTestValue('asdtoet'); // jshint ignore:line

    //
    // Exclude specified schools from showing up
    //
    getTestValue('exclude_schools');

    const ctname = getTestValue('ctname', false);
    const ctvalue = getTestValue('ctvalue', false);
    if (ctname && ctvalue) {
        setTestValue(ctname, ctvalue);
    }
    const collabRelated = getTestValue('collab_related') === 'true';
    if (collabRelated) {
        ASD_SETTINGS.collabRelated = true;
    }
    const useRfiWizardVariant = getTestValue('wizard_rfi') === 'true';
    if (useRfiWizardVariant) {
        ASD_SETTINGS.isWizardRfi = true;
    }

    const useWizardLightbox = getTestValue('wizard_lightbox') === 'true'; //getTestValue('WIZARD_LIGHTBOX') === 'true';
    if (useWizardLightbox) {
        ASD_SETTINGS.isWizardRfi = true;
        ASD_SETTINGS.useWizardLightbox = true;
    }

    // TECH-14315 - For DIRECT_RFI Sitespect Variant
    const useDirectToRfiFlow = getTestValue('direct_rfi') === 'true';
    if (useDirectToRfiFlow) {
        ASD_SETTINGS.useDirectToRfiFlow = true;
    }

    // DEPRECATED and replaced with React RFI - delete this code once we can
    const isBackboneRfi = getTestValue('backbone_rfi') === 'true';
    if (isBackboneRfi) {
        ASD_SETTINGS.isBackboneRfi = true;
    }

    // Flip the fresh RFI test as legacy_rfi to allow surfacing the legacy experience
    const isLegacyRfi = getTestValue('legacy_rfi') === 'true';
    if (isLegacyRfi) {
        ASD_SETTINGS.isLegacyRfi = true;
    }

    const isFreshRfi = getTestValue('fresh_rfi') === 'true';
    if (isFreshRfi) {
        ASD_SETTINGS.isFreshRfiTest = true;
    }

    const isNotFreshWizardRfi = getTestValue('fresh_wizard') === 'false';
    if (isNotFreshWizardRfi || isIE()) {
        ASD_SETTINGS.isFreshWizardRfi = false;
    }

    const isWizardLight = getTestValue('mw_light') === 'true';
    if (isWizardLight) {
        ASD_SETTINGS.isWizardLight = true;
    }

    const prePingSuggestions = getTestValue('suggest_ping') === 'true';
    if (prePingSuggestions) {
        ASD_SETTINGS.prePingSuggestions = true;
    }

    const suggestHide = getTestValue('suggest_hide') === 'true';
    if (suggestHide) {
        ASD_SETTINGS.prePingSuggestHide = true;
    }

    const dupeShow = getTestValue('dupe_show') === 'true';
    if (dupeShow) {
        ASD_SETTINGS.dupeShow = true;
    }

    const listingsPing = getTestValue('listings_ping') === 'true';
    if (listingsPing) {
        ASD_SETTINGS.listingsPing = true;
    }

    const progLimit = getTestValue('prog_limit_3') === 'true';
    if (progLimit) {
        ASD_SETTINGS.progLimit = true;
    }

    const listingsFilter = getTestValue('listings_filter') === 'true';
    if (listingsFilter) {
        ASD_SETTINGS.listingsFilter = true;
    }

    const wizardFirst = getTestValue('first_select') === 'true';
    if (wizardFirst && !isIE() && !navigator.userAgent.match(/SamsungBrowser/i)) {
        ASD_SETTINGS.wizardFirst = true;
        ASD_SETTINGS.isFreshWizardRfi = true;
        if (window.screen.width <= 600) {
            ASD_SETTINGS.isWizardLight = true;
            ASD_SETTINGS.isDesktopFreshWizard = true;
        }
    }

    // this should get defaulted to true so that linkout is the default experience and linkout_below shows by default.
    ASD_SETTINGS.linkoutEnabled = true;
    ASD_SETTINGS.integratedLinkoutEnabled = true;

    const linkoutOnlyEnabled = getTestValue('linkout_only') === 'true';
    if (linkoutOnlyEnabled || sessionStorage.getItem('linkoutOnly')) {
        ASD_SETTINGS.linkoutEnabled = true;
        ASD_SETTINGS.linkoutOnlyEnabled = true;
        sessionStorage.setItem('linkoutOnly', true); // persist this setting in a session
    }

    const linkoutTrackingParam = getQueryParam('linkout_mock_flow') === 'true';
    if (linkoutTrackingParam) {
        // ONLY set mock flow enabled. To be used in conjunction with other linkout params now.
        ASD_SETTINGS.mockLinkoutFlowEnabled = true;
    }

    const linkoutLogoParam = getTestValue('linkout_logo') === 'true';
    if (linkoutLogoParam) {
        ASD_SETTINGS.linkoutEnabled = true;
        ASD_SETTINGS.linkoutLogoEnabled = true;
    }

    const logosEnabled = getTestValue('logos_enabled') === 'true';
    if (logosEnabled) {
        ASD_SETTINGS.logosEnabled = true;
    }

    const updatedPrefilterPage = getTestValue('prefilter_image') === 'true';
    if (updatedPrefilterPage) {
        ASD_SETTINGS.updatedPrefilterPage = true;
    }

    const mobilePrefilter = getTestValue('mobile_prefilter') === 'true';
    if (mobilePrefilter) {
        ASD_SETTINGS.mobilePrefilter = true;
    }

    const newListingsUI = getTestValue('2022_ui') === 'true';
    if (newListingsUI) {
        ASD_SETTINGS.newListingsUI = true;
    }

    const filteredSchools = getTestValue('filtered_schools') === 'true';
    if (filteredSchools) {
        ASD_SETTINGS.filteredSchools = true;
    }

    const prefilterAnswers = getTestValue('prefilter_answers') === 'true';
    if (prefilterAnswers) {
        ASD_SETTINGS.prefilterAnswers = true;
    }

    const linkoutMashup = getTestValue('linkout_mashup') === 'true';
    if (linkoutMashup) {
        ASD_SETTINGS.linkoutAbove = true;
        ASD_SETTINGS.linkoutLogoEnabled = true;
        ASD_SETTINGS.isDesktopFreshWizard = true;
        ASD_SETTINGS.isWizardLight = true;
    }

    const linkoutMashup2 = getTestValue('linkout_mashup2') === 'true';
    if (linkoutMashup2) {
        ASD_SETTINGS.linkoutAbove = true;
        ASD_SETTINGS.isDesktopFreshWizard = true;
        ASD_SETTINGS.isWizardLight = true;
    }

    const sortedListings = getTestValue('sort_schools') === 'true';
    if (sortedListings) {
        ASD_SETTINGS.sortedListings = true;
    }

    // Enable Blender
    getTestValue('blender_enabled');

    // Limit number of basic listings
    getTestValue('limit_basic');

    // iOS Modal scrolling fix
    const iosScrollingFix = getTestValue('ios_scrolling_fix') === 'true';
    if (iosScrollingFix) {
        ASD_SETTINGS.iosScrollingFix = true;
    }

    // To allow surfacing of Eddy listings with a url param (not an actual A/B test)
    const foreignEddy = getTestValue('foreign_eddy') === 'true';
    if (foreignEddy) {
        ASD_SETTINGS.foreignEddy = true;
    }

    // Optimizations to the Fresh RFI experience
    const freshRfiOptimized = getTestValue('fresh_rfi_optimized') === 'true';
    if (freshRfiOptimized) {
        ASD_SETTINGS.freshRfiOptimized = true;
    }

    // Direct RFI
    const directFunnel = getTestValue('direct_funnel') === 'true';
    if (directFunnel) {
        ASD_SETTINGS.directFunnelBucket = true;
        ASD_SETTINGS.directFunnel = false;
        if (window.screen.width <= 600) {
            ASD_SETTINGS.isFreshWizardRfi = true;
        }
    }

    const listingsMessage = getTestValue('listings_message') === 'true';
    if (listingsMessage) {
        ASD_SETTINGS.listingsMessage = true;
    }

    const useMainClient = getTestValue('lt_client') === 'true'; // if true, load main, if false load LT

    //
    // Do jquery presence and version detect
    //
    let loadJquery = !window.jQuery;

    if (!loadJquery) {
        try {
            if (versionCompare(window.jQuery().jquery, '1.7') === -1) {
                window.ASD_JQUERY_RESTORE = true;
                loadJquery = true;
            }
        } catch (e) {
            //
            // Could not call jquery, load the newer version.  Do not restore
            // the bad version.
            //
            loadJquery = true;
        }
    }

    let themeName = '-theme-origin';
    const themeArray = [
        'blue',
        'cool-green',
        'gray',
        'earth-tones',
        'pinstripe',
        'geometrix',
        'gumdrops',
    ];
    for (let i = 0; i < themeArray.length; i++) {
        if (themeArray[i] === ASD_SETTINGS.visualTheme) {
            themeName = '-theme-' + ASD_SETTINGS.visualTheme;
        }
    }

    let clientChunk = '/asd-client-main.js'; // initialize to main client chunk
    let styleChunk = '/asd-client' + themeName + (localDevEnvironment ? '.js' : '.css');

    const widgetList = document.getElementsByClassName('asd-client-widget');

    const isNoContent =
        !widgetList.length &&
        !document.getElementById('asd-client-content') &&
        !document.getElementById('asd-content');

    let isCname = false;

    if (ASD_SETTINGS.cname) {
        if (!Object.values(ASD_SETTINGS.cname).every(el => !el)) {
            isCname = true;
        }
    }

    const minimalExclusions =
        isIE() ||
        sessionStorage.getItem('asdMainLoaded') ||
        isCname ||
        document.getElementById(
            ASD_SETTINGS.targetElement ? ASD_SETTINGS.targetElement.replace('#', '') : 'asd-content'
        );

    // const isMinimalLT = widgetList ? Array.from(widgetList).every(widget => widget.attributes.type ?
    //     widget.attributes.type.nodeValue == "cta_program_button" : false) && Array.from(widgetList).length > 0 && !minimalExclusions : false;

    const isMinimal = widgetList
        ? Array.from(widgetList).every(widget =>
            widget.attributes.type
                ? widget.attributes.type.nodeValue == 'cta_program_button' ||
                widget.attributes.type.nodeValue == 'cta_program_prefilter_box'
                : false
        ) &&
        Array.from(widgetList).length > 0 &&
        !minimalExclusions
        : false;
    const inContentLinkoutsValid = widgetList
        ? Array.from(widgetList).find(widget =>
            widget.attributes.type
                ? widget.attributes.type.nodeValue == 'in_content_linkout' ||
                widget.attributes.type.nodeValue == 'standalone_linkout'
                : false
        ) && Array.from(widgetList).length > 0
        : null;

    if (isNoContent) {
        clientChunk = '/asd-client-min-no-content.js';
    } else if (inContentLinkoutsValid) {
        clientChunk = '/asd-client-min-in-content-linkout.js';
        styleChunk = '/asd-minimal-client-widgets' + (localDevEnvironment ? '.js' : '.css');
        ASD_SETTINGS.clientType = 'minimal'; //Should we keep this or rename it?
    } else if (isMinimal && useMainClient == false) {
        clientChunk = '/asd-client-min-widgets-lt.js';
        styleChunk = '/asd-minimal-client-widgets' + (localDevEnvironment ? '.js' : '.css');
    } else {
        sessionStorage.setItem('asdMainLoaded', true); // only use main chunk once it has been loaded in a session
        ASD_SETTINGS.clientType = 'main';
    }

    const clientModVersion = getTestValue('clientmod');
    if (clientModVersion == 'st') {
        clientDownloadPath = 'https://cdn.clientst.allstardirectories.com/client';
    } else if (clientModVersion == 'lt') {
        clientDownloadPath = 'https://cdn.clientlt.allstardirectories.com/client';
    }

    const clientPath = clientDownloadPath + clientChunk;
    const stylePath = clientDownloadPath + styleChunk;
    //
    // Load the css and js file
    //
    window.yepnope([
        {
            test: loadJquery,
            yep: ['//ajax.googleapis.com/ajax/libs/jquery/1.11.1/jquery.min.js'],
            both: isNoContent
                ? [
                    // commeneted out for now, until we minify this stylePath,

                    clientPath,
                ]
                : [
                    // commeneted out for now, until we minify this stylePath,
                    stylePath,
                    clientPath,
                ],
        },
    ]);
}
