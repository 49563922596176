import {init} from '@sentry/browser';

module.exports = {
    sentryLoad: function (env) {
        const sentryAllowedEnviroments = ['dev', 'perf', 'prod'];

        if (sentryAllowedEnviroments.includes(env)) {
            init({
                dsn: `https://088221b8fe0d43a59e93b5265cdecb1b@o1201543.ingest.sentry.io/6326346`,
                dist: `8.0`,
                environment: env,
                beforeSend(event, hint) {
                    const errorStack = hint.originalException.stack;
                    const clientError = errorStack.match(/(asd-client)/gm);
                    if (clientError) {
                        return event;
                    }
                },
            });
        }
    },
};
