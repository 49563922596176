/**
 * WARNING
 * WARNING This file is included in our minimal JS loader.  As such, we need to be very careful
 * WARNING about what we load in this file.  We don't want it to become too big.  We don't want to
 * WARNING even include common libraries like lodash or jquery.
 * WARNING
 */

/**
 * Since we can't get third party cookies on CORS requests, SiteSpect encodes cookies in custom
 * headers.  We then put then put the header contents in cookies on the domain the client is
 * loaded.  This file contains utilities to support setting the appropriate headers on requests and
 * parsing the appropriate response headers so they can be stored in cookies.
 */


export default {
    requestHeader: 'X-SiteSpect-Cookie', // for sending cookies to SiteSpect
    responseHeader: 'X-Set-SiteSpect-Cookie', // for receiving SiteSpect cookies
    notABotHeader: 'X-Not-A-Bot', // lets SiteSpect know that we are not a bot
    testHeaderPrefix: 'x-asd-test-', // prefix we use by convention for our SiteSpect A/B test headers
    // fix CORS console error
    // accessControlOrigin: 'Access-Control-Allow-Origin', // to avoid duplicate requests in case Cross-Origin Resource Sharing (CORS)
    /**
     * Parse the SiteSpect response header values in to individual cookie values
     * @param header
     * @return {Array}
     */
    parseCookieHeader(header) {
        if(header) {
            // Our regex will match on the commas that separate our cookie values, but not the commas that separate our expiration
            // date string.
            const regex = /(.+?)(?:,\s(?=\D)|$)/g;

            // Retrieve our individual cookie values from "X-Set-SiteSpect-Cookie".
            const siteSpectCookies = [];
            let match;
            while ((match = regex.exec(header)) !== null) {
                const originalCookie = match[1];
                const domain = this.getTopPrivateDomainFromHost(document.location.hostname);

                const localCookie = originalCookie.replace(/domain.+?((?=;)|$)/, `domain=.${domain}`);
                siteSpectCookies.push(localCookie);
            }

            return siteSpectCookies;
        } else {
            return [];
        }
    },

    /**
     * Attempts to find the top private domain in the passed in host name. If it cannot find one,
     * it returns the original input.
     * @param {string} hostname
     * @return {string}
     */
    getTopPrivateDomainFromHost(hostname) {
        const supportedTopLevelDomains = ['com', 'net', 'info', 'org', 'edu'];
        const hostParts = hostname.split('.');

        if(supportedTopLevelDomains.indexOf(hostParts.slice(-1)[0]) >= 0) {
            return hostParts.slice(-2).join('.')
        } else {
            return hostname;
        }
    }
};
